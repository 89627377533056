import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Cierre Grupo Electrogeno',
        texto: 'Proyecto de Cierre Grupo Electrógeno empresa Distintec de la comuna de Lampa. Se realiza estructura metálica para cubrir Equipo Generador con techo de zinc y perímetro de malla Acmafor. Puerta con chapa y llave.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720849042/0_fepx2x.jpg', // Reduced resolution
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849460/1_in5tfv.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849467/2_hwd1vr.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849495/3_bpjekv.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849502/4_etyle4.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849520/5_nheyjs.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849568/6_znk17w.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849579/7_ubpe5r.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849591/8_f7ulxs.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849601/9_jrpxbf.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849613/10_mwireg.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849622/11_upm2dk.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849628/12_r3tr2n.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720849635/13_zurc9t.jpg',
        ],
        styles: 'fade-left'
    },
];

export const Electrogeno = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
        setIndex(index);
        setIsOpen(true);
    };

    const isMobile = window.innerWidth < 1250;

    return (
        <>
            <div className='card' data-aos={`${isMobile ? 'zoom-in' : 'fade-left'}`} data-aos-duration="1000">
                <figure onClick={() => openGallery(0)}>
                    <img src={proyectosRealizados_1[0].imagePrincipal} alt=''/>
                </figure>
                <div className='contenido'>
                    <h3>{proyectosRealizados_1[0].nombre}</h3>
                    <p>{proyectosRealizados_1[0].texto}<br/><br/><br/></p>
                    <Link onClick={() => setActiveLink('')} className='link' to='contacto'>Consulte por su Proyecto</Link>
                </div>
            </div>

            <PestañaGaleria
                key={proyectosRealizados_1[0].nombre}
                index={index}
                isOpen={isOpen}
                images={proyectosRealizados_1[0]}
                setIndex={setIndex}
                setIsOpen={setIsOpen}
            />
        </>
    );
};
