import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServicioSeguridad = () => {

  const isMobile = window.innerWidth < 1250;

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <>

        <Contruction />

        <div className='container quienes-somos servicios-enlace'>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Sistema de </span>Seguridad</h1>

            <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720772558/1_dlvpas.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` }data-aos-duration="1000">
                    Soluciones en diseño, ejecución de
                    proyectos y mantenimiento de sistemas de seguridad y 
                    control de acceso. Contamos con soluciones para 
                    ambientes comerciales, públicos e industriales. 
                    Nuestro personal es altamente capacitado para cada 
                    una de las tareas a desarrollar en la instalación de 
                    cada proyecto.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                    <span>Servicios de seguridad:</span>
                    <br />
                    • Diseño e instalación de sistema de control de acceso
                    <br />
                    • Instalación de motor para portón acceso vehicular
                    <br />
                    • Instalación de barrera para control vehicular
                    <br />
                    • Diseño e instalación de sistema de CCTV
                    <br />
                    • Diseño e instalación de sistema de intrusión 
                    <br />
                    • Instalación y mantenimiento de cercos eléctricos
                    <br />
                    • Solución de acceso peatonal y vehicular
                    <br />
                    • Mantenciones preventivas y correctivas
                    <br />
                    • Diseño e implementación de salas de monitoreo
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p data-aos="fade-right" data-aos-duration="1000">
                    <span>Servicios de seguridad:</span>
                    <br />
                    • Diseño e instalación de sistema de control de acceso
                    <br />
                    • Instalación de motor para portón acceso vehicular
                    <br />
                    • Instalación de barrera para control vehicular
                    <br />
                    • Diseño e instalación de sistema de CCTV
                    <br />
                    • Diseño e instalación de sistema de intrusión 
                    <br />
                    • Instalación y mantenimiento de cercos eléctricos
                    <br />
                    • Solución de acceso peatonal y vehicular
                    <br />
                    • Mantenciones preventivas y correctivas
                    <br />
                    • Diseño e implementación de salas de monitoreo
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720772564/2_nj80yl.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720772570/3_gvmibd.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                </div>
            </div>

            <div className='quienes-somos-text servicios-page'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720772577/4_tezlej.png' alt='quienes-somos' ddata-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720772582/5_c7kkyt.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720829190/6_rhq1ls.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
            </div>

        </div>

    </>
  );
};
