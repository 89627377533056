import { useEffect, useState } from 'react';

export const CounterAutomatic = ( value, contadorRef ) => {

    const [ counter, setCounter ] = useState( value.contador_inicial );

    useEffect( () => {

        const mostrarContadores = ( elementos ) => {

            elementos.forEach( ( elemento ) => {

                if ( elemento.isIntersecting ) {

                    elemento.target.classList.add('animar');
                    elemento.target.classList.remove('ocultar');
    
                    const interval = setInterval( () => {

                        setCounter( ( prevCounter ) => {

                            if ( prevCounter >= value.contador_actual ) {

                                clearInterval( interval );


                                return prevCounter;
                            }

                            return prevCounter + 1;

                        });

                    }, value.seconds );

    
                    observer.unobserve( elemento.target );

                }
            });
        };
    
        const observer = new IntersectionObserver( mostrarContadores, {

          threshold: 1,

        });
    
        if ( contadorRef.current ) {

          observer.observe( contadorRef.current );

        }
    
        return () => {

            if ( contadorRef.current ) {

            observer.unobserve( contadorRef.current );

            }
        };

      }, [ value.contador_actual, contadorRef, value.seconds ] );

    return {

        counter: counter,

    }

};
