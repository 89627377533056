import React, { useState } from 'react';
import '../../styles/socials.css';

export const Socials = () => {

  const [ isActive , setisActive ] = useState( '' );

  const socialClick = () => {

    if ( isActive === 'active' ) {

      return ( setisActive( 'no-active' ) );

    }

    setisActive( 'active' )

  };

  return (
    <div className='socials'>
        <div className={ `socials-main ${ isActive }` }>
            <ul>
                <li><a href='https://www.facebook.com/insertecchile' target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                <li><a href='https://www.instagram.com/insertec.cl/' target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                <li><a href='https://www.linkedin.com/company/inserte-cl/?viewAsMember=true' target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                <li><a href='https://api.whatsapp.com/send/?phone=56926199538&text&type=phone_number&app_absent=0' target="_blank"><i className="fa-brands fa-whatsapp"></i></a></li>
            </ul>
        </div>
        <i onClick={ () => socialClick() } className="fa-solid fa-share-nodes"></i>
    </div>
  );
};
