import React from 'react';
import { HashLink } from 'react-router-hash-link';
import '../../styles/footer.css';
import { Link } from 'react-router-dom';

export const Footer = ({ setActiveLink }) => {

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <>
      <footer className='footer'>
        <div className='container container-footer'>
          <div className='menu-footer'>

            <div className='info'>
              <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721182695/navbar.01c3f99c9dca55a2aaea_w4tlg6.png' alt='footer' />
              <p>
                Somos un partner de negocios que 
                entrega soluciones integrales de 
                ingeniería para tu empresa.
              </p>
            </div>

            <div className='menufooter'>
              <p className='title-footer'>Menu</p>
              <ul>
                <li>
                  <Link to='/' className='link-footer' onClick={() => handleLinkClick('/')}>Inicio</Link>
                </li>
                <li>
                  <HashLink to='/#quienes-somos' className='link-footer' onClick={() => handleLinkClick('/#quienes-somos')}><span>Quiénes</span> Somos</HashLink>
                </li>                  
                <li>
                  <HashLink to='/#nuestros-servicios' className='link-footer' onClick={() => handleLinkClick('/#nuestros-servicios')}><span>Nuestros</span> Servicios</HashLink>
                </li>                  
                <li>
                  <HashLink to='/#proyectos-realizados' className='link-footer' onClick={() => handleLinkClick('/#proyectos-realizados')}>Proyectos <span>Realizados</span></HashLink>
                </li>                  
                <li>
                  <HashLink to='/#experiencia' className='link-footer' onClick={() => handleLinkClick('/#experiencia')}>Experiencia</HashLink>
                </li>
                <li>
                  <Link to='/contacto' className='link-footer' onClick={() => handleLinkClick('/contacto')}>Contacto</Link>
                </li>
              </ul>
            </div>

            <div className='informacion-adicional'>
              <p className='title-footer'>Servicios</p>
              <ul>
                <li>
                  <Link to='/nuestros-servicios/electricidad'>Electricidad</Link>                  
                </li>
                <li>
                  <Link to='/nuestros-servicios/contruccion'>Construcción</Link>                  
                </li>
                <li>
                  <Link to='/nuestros-servicios/conectividad-y-redes'>Conectividad y Redes</Link>                  
                </li>
                <li>
                  <Link to='/nuestros-servicios/sistema-de-seguridad'>Sistema de Seguridad</Link>                  
                </li>
                <li>
                  <Link to='/nuestros-servicios/automatizacion-domotica'>Automatización - Domótica</Link>                  
                </li>
                <li>
                  <Link to='/nuestros-servicios/generacion-y-respaldo-de-energia'>Generación y Respaldo de Energía</Link>                  
                </li>
              </ul>
            </div>

            <div className='contacto'>
              <p className='title-footer'>Contáctanos</p>
              <ul>
                <li><i className="fa-solid fa-location-dot"></i>Dirección: AV. Apoquindo 6410 OF 605 PS 6, Las Condes</li>
                <li><i className="fa-solid fa-phone"></i>Teléfono: +569 26199538</li>
                <li><a className='correo' href='mailto:contacto@insertec-cl.cl'><i className="fa-regular fa-envelope"></i>Email: contacto@insertec-cl.cl</a></li>
              </ul>
              <ul className='redes-sociales'>
                <li className='facebook'><a  href='https://www.facebook.com/insertecchile' target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                <li className='instagram'><a href='https://www.instagram.com/insertec.cl/' target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                <li className='twiter'><a href='https://www.linkedin.com/company/inserte-cl/?viewAsMember=true' target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                <li className='whatsapp'><a href='https://api.whatsapp.com/send/?phone=56926199538&text&type=phone_number&app_absent=0' target="_blank"><i className="fa-brands fa-whatsapp"></i></a></li>
              </ul>
            </div>

          </div>
        </div>
      </footer>

      <div className='copyright'>
        <p>
          Copyright © 2024 Insertec-cl | Desarrollado por <a href='https://github.com/llAndyNicll' target="_blank">AndyNic</a> 
        </p>
      </div>
    </>
  );
};
