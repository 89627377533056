import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import '../../styles/navbar.css';

export const Navbar = ({ activeLink, setActiveLink }) => {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);

  const handleNavbarToggle = () => {
    setActive(!active);
  };

  const handleNavbar2Toggle = () => {
    setActive2(!active2);
  };

  const handleSetActiveLink = (path) => {
    if (path === '/') {
      window.scrollTo(0, 0);
    }
    setActiveLink(path);
    setActive(false); // Cierra el menú en dispositivos móviles al hacer clic
    setActive2(false); // Cierra el menú desplegable si está abierto
  };

  const handleSubMenuClick = () => {
    setActiveLink(''); // Desactiva cualquier enlace activo del menú principal
    setActive(false); // Cierra el menú en dispositivos móviles al hacer clic
    setActive2(false); // Cierra el menú desplegable si está abierto
  };

  return (
    <>
      <div className='container-navbar'>
        <nav className='navbar container'>
          <a href='/' ><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721103435/logo-removebg-preview_sih93m.png' /></a>
          <p><i onClick={handleNavbarToggle} className='fa-solid fa-bars'></i></p>
          <ul className='menu'>
            <li>
              <NavLink 
                to='/' 
                className={({ isActive }) => (isActive && activeLink === '/') ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/')}
              >
                Inicio
              </NavLink>
            </li>
            <li>
              <HashLink 
                to='/#quienes-somos' 
                className={activeLink === '/#quienes-somos' ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/#quienes-somos')}
              >
                <span>Quiénes</span> Somos
              </HashLink>
            </li>
            <li className='dropdown'>
              <HashLink 
                to='/#nuestros-servicios' 
                className={activeLink === '/#nuestros-servicios' ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/#nuestros-servicios')}
              >
                <span>Nuestros</span> Servicios <i className="fa-solid fa-chevron-down"></i>
              </HashLink>
              <ul className='dropdown-content' data-aos="fade-down">
                <li><Link to='nuestros-servicios/electricidad' onClick={handleSubMenuClick}>- Electricidad</Link></li>
                <li><Link to='nuestros-servicios/contruccion' onClick={handleSubMenuClick}>- Construcción</Link></li>
                <li><Link to='nuestros-servicios/conectividad-y-redes' onClick={handleSubMenuClick}>- Conectividad y Redes</Link></li>
                <li><Link to='nuestros-servicios/sistema-de-seguridad' onClick={handleSubMenuClick}>- Sistema de Seguridad</Link></li>
                <li><Link to='nuestros-servicios/automatizacion-domotica' onClick={handleSubMenuClick}>- Automatización - Domótica</Link></li>
                <li><Link to='nuestros-servicios/generacion-y-respaldo-de-energia' onClick={handleSubMenuClick}>- Generación y Respaldo de Energía</Link></li>
              </ul>
            </li>
            <li>
              <HashLink 
                to='/#proyectos-realizados' 
                className={activeLink === '/#proyectos-realizados' ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/#proyectos-realizados')}
              >
                Proyectos <span>Realizados</span>
              </HashLink>
            </li>
            <li>
              <HashLink 
                to='/#experiencia' 
                className={activeLink === '/#experiencia' ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/#experiencia')}
              >
                Experiencia
              </HashLink>
            </li>
            <li>
              <NavLink 
                to='/contacto' 
                className={({ isActive }) => (isActive || activeLink === '/contacto') ? 'navlink nav-active' : 'navlink'} 
                onClick={() => handleSetActiveLink('/contacto')}
              >
                Contacto
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`nuevo-menu ${active ? 'menu-active animate__animated animate__fadeInDown' : ''}`}>
        <ul className='menu-responsive'>
          <li>
            <NavLink 
              to='/' 
              className={({ isActive }) => (isActive && activeLink === '/') ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/')}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <HashLink 
              to='/#quienes-somos' 
              className={activeLink === '/#quienes-somos' ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/#quienes-somos')}
            >
              <span>Quiénes</span> Somos
            </HashLink>
          </li>
          <li className='dropdown2'>
            <HashLink 
              to='/#nuestros-servicios' 
              className={activeLink === '/#nuestros-servicios' ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/#nuestros-servicios')}
            >
              <span>Nuestros</span> Servicios 
            </HashLink>
            {active2 ? (<i onClick={handleNavbar2Toggle} className="fa-solid fa-chevron-up animate__animated animate__fadeIn"></i>) : (<i onClick={handleNavbar2Toggle} className="fa-solid fa-chevron-down"></i>)}
          </li>
          <ul className={`dropdown-content-1 ${active2 ? 'dropdown-1-active animate__animated animate__fadeIn' : ''}`}>
            <li><Link to='nuestros-servicios/electricidad' onClick={handleSubMenuClick}>- Electricidad</Link></li>
            <li><Link to='nuestros-servicios/contruccion' onClick={handleSubMenuClick}>- Construcción</Link></li>
            <li><Link to='nuestros-servicios/conectividad-y-redes' onClick={handleSubMenuClick}>- Conectividad y Redes</Link></li>
            <li><Link to='nuestros-servicios/sistema-de-seguridad' onClick={handleSubMenuClick}>- Sistema de Seguridad</Link></li>
            <li><Link to='nuestros-servicios/automatizacion-domotica' onClick={handleSubMenuClick}>- Automatización - Domótica</Link></li>
            <li><Link to='nuestros-servicios/generacion-y-respaldo-de-energia' onClick={handleSubMenuClick}>- Generación y Respaldo de Energía</Link></li>
          </ul>
          <li>
            <HashLink 
              to='/#proyectos-realizados' 
              className={activeLink === '/#proyectos-realizados' ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/#proyectos-realizados')}
            >
              Proyectos <span>Realizados</span>
            </HashLink>
          </li>
          <li>
            <HashLink 
              to='/#experiencia' 
              className={activeLink === '/#experiencia' ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/#experiencia')}
            >
              Experiencia
            </HashLink>
          </li>
          <li>
            <NavLink 
              to='/contacto' 
              className={({ isActive }) => (isActive || activeLink === '/contacto') ? 'navlink nav-active' : 'navlink'} 
              onClick={() => handleSetActiveLink('/contacto')}
            >
              Contacto
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
