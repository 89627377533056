
import { InicioPage, ContactoPage } from "../pages/export";

export const routes = [
    {
        name: 'Inicio' ,
        id: '0' ,
        to: '/' ,
        path: '/' ,
        Component: InicioPage,
    },
    {
        name: 'Contacto' ,
        id: '1' ,
        to: 'Contacto' ,
        path: 'Contacto' ,
        Component: ContactoPage,
    },
];