import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServiciosElectricidad = () => {

  const isMobile = window.innerWidth < 1250;

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <>

        <Contruction />

        <div className='container quienes-somos servicios-enlace'>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Electr</span>icidad</h1>

            <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763474/1_ahmt9f.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    Ingeniería, diseño y ejecución de proyectos, soluciones 
                    en instalaciones de media y baja tensión. Contamos con 
                    una gran experiencia en proyectos integrales, los que nos 
                    permite una alta visión desde la ingeniaría para abordar 
                    los proyectos. Nuestro personal es altamente especializado 
                    en montajes de redes eléctricas. Diseñamos soluciones a 
                    medida.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                <span>Servicios eléctricos:</span>
                    <br />
                    • Mantenciones eléctricas correctivas y preventivas
                    <br />
                    • Instalación y mantención de tableros eléctricos
                    <br />
                    • Instalaciones de mediana y baja tensión 
                    <br />
                    • Integración de proyectos eléctricos con climatización, UPS, generadores y redes
                    <br />
                    • Integración de tableros eléctricos
                    <br />
                    • Aumentos de capacidad y empalmes
                    <br />
                    • Instalaciones eléctricas industriales, comerciales, y domiciliarias
                    <br />
                    • Control y automatización 
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p data-aos="fade-right" data-aos-duration="1000">
                    <span>Servicios eléctricos:</span>
                    <br />
                    • Mantenciones eléctricas correctivas y preventivas
                    <br />
                    • Instalación y mantención de tableros eléctricos
                    <br />
                    • Instalaciones de mediana y baja tensión 
                    <br />
                    • Integración de proyectos eléctricos con climatización, UPS,<br /><span className='texto-adicional'>generadores y redes</span>
                    <br />
                    • Integración de tableros eléctricos
                    <br />
                    • Aumentos de capacidad y empalmes
                    <br />
                    • Instalaciones eléctricas industriales, comerciales, y domiciliarias
                    <br />
                    • Control y automatización 
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763480/2_t4t8no.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763485/3_bfzouo.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                </div>
            </div>

            <div className='quienes-somos-text servicios-page'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763491/4_aduwuq.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763496/5_iznulg.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720763504/6_dmwtf1.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
            </div>
        </div>

    </>
  );
};
