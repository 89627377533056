import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServiciosContruccion = () => {

    const isMobile = window.innerWidth < 1250;

    useEffect(() => {
        // Función que hace scroll hasta el tope
        window.scrollTo(0, 0);
      }, [  ] );

  return (
    <>

        <Contruction />

        <div className='container quienes-somos servicios-enlace'>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Constr</span>ucción</h1>

            <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771350/1_myz9vp.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    En INSERTEC contamos con un equipo capacitado multidisciplinario. 
                    Ofrecemos soluciones integrales a los requerimientos de cada 
                    proyecto, generando economías de escala y optimización en los 
                    tiempos de ejecución. Analizamos, Asesoramos, Administramos y 
                    Ejecutamos diversos proyectos en obras menores de la Construcción, 
                    nos hacemos participes en todas las etapas, desde el diseño hasta 
                    la construcción. Entregamos soluciones para modificar la 
                    organización y/o estructuración de un recinto según los 
                    requerimientos de cada cliente, ya sea del área Industrial, 
                    Comercial o Particular.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                <span>Servicios de construcción:</span>
                    <br />
                    • Construcciones y Remodelaciones generales
                    <br />
                    • Estructuras metálicas
                    <br />
                    • Gasfitería
                    <br />
                    • Carpintería
                    <br />
                    • Pinturas
                    <br />
                    • Albañilería
                    <br />
                    • Pisos
                    <br />
                    • Techumbres
                    <br />
                    • Cielos americanos y falsos
                    <br />
                    • Revestimientos
                    <br />
                    • Entre otros
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p data-aos="fade-right" data-aos-duration="1000">
                    <span>Servicios de construcción:</span>
                    <br />
                    • Construcciones y Remodelaciones generales
                    <br />
                    • Estructuras metálicas
                    <br />
                    • Gasfitería
                    <br />
                    • Carpintería
                    <br />
                    • Pinturas
                    <br />
                    • Albañilería
                    <br />
                    • Pisos
                    <br />
                    • Techumbres
                    <br />
                    • Cielos americanos y falsos
                    <br />
                    • Revestimientos
                    <br />
                    • Entre otros
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771357/2_et2wnp.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771366/3_mr6c9a.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                </div>
            </div>

            <div className='quienes-somos-text servicios-page'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771376/4_in0l8q.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771386/5_we3w7k.jpg' alt='quienes-somos' ddata-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720829057/6_rcdam0.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
            </div>

        </div>

    </>
  );
};
