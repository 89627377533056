import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const BannerSection = ({ setActiveLink }) => {

  const isMobile = window.innerWidth < 1250

  const [currentIndex, setCurrentIndex] = useState(0);

  const banners = [
    {
      title: 'Construcción, Remodelación y Servicios',
      content: `
        Nos enfocamos en solucionar tus problemas
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720773246/1_tyaklk.jpg'
    },
    {
      title: 'Electricidad',
      content: `
        Confía en manos expertos certificados SEC <br /> el diseño, la planificación, ejecución y <br /> certificación de proyectos
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721113890/2_klwtlm.jpg'
    },
    {
      title: 'Automatización y Domotica',
      content: `
        Expertos en soluciones de ingeniería y diseño
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721158984/3_fzrdnq.jpg'
    },
    {
      title: 'Seguridad',
      content: `
        Tecnologías en diseño e instalación de sistema <br /> de control de acceso, cctv y instrucción
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721158990/4_nexkig.jpg'
    },
    {
      title: 'Generación de Energía',
      content: `
        Soluciones en diseño y ejecución de proyectos <br /> de baja y media tensión
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721113890/5_bu51vw.jpg'
    }
  ];

    const banners2 = [
    {
      title: 'Construcción, Remodelación y Servicios',
      content: `
        Nos enfocamos en solucionar tus problemas
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720773246/1_tyaklk.jpg'
    },
    {
      title: 'Electricidad',
      content: `
        Confía en manos expertos certificados SEC el diseño, la planificación, ejecución y certificación de proyectos
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721113890/2_klwtlm.jpg'
    },
    {
      title: 'Automatización y Domotica',
      content: `
        Expertos en soluciones de ingeniería y diseño
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721158984/3_fzrdnq.jpg'
    },
    {
      title: 'Seguridad',
      content: `
        Tecnologías en diseño e instalación de sistema de control de acceso, cctv y instrucción
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721158990/4_nexkig.jpg'
    },
    {
      title: 'Generación de Energía',
      content: `
        Soluciones en diseño y ejecución de proyectos de baja y media tensión
      `,
      link: '/contacto',
      image: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721113890/5_bu51vw.jpg'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length );
    }, 5000);

    return () => clearInterval(interval);
  }, [ banners.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners2.length );
    }, 5000);

    return () => clearInterval(interval);
  }, [ banners2.length]);

  return (
    <>

      <div className='container-banner'>
        { isMobile ?
          banners2.map((banner, index) => (
  <div
    key={index}
    className={`content-banner ${index === currentIndex ? 'active' : ''}`}
    style={{ backgroundImage: `linear-gradient(100deg, #000000, #00000020), url(${banner.image})` }}
  >
    <h1 data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="1000">{banner.title}</h1>
    <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="1500" dangerouslySetInnerHTML={{ __html: banner.content }}></p>
    <span>
      <Link onClick={ () => setActiveLink( '' ) } className='link' data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="2000" to='contacto'>Contáctanos</Link>
    </span>
  </div>     
  )):  
        
        banners.map((banner, index) => (
        <div
          key={index}
          className={`content-banner ${index === currentIndex ? 'active' : ''}`}
          style={{ backgroundImage: `linear-gradient(100deg, #000000, #00000020), url(${banner.image})` }}
        >
          <h1 data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="1000">{banner.title}</h1>
          <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="1500" dangerouslySetInnerHTML={{ __html: banner.content }}></p>
          <span>
            <Link onClick={ () => setActiveLink( '' ) } className='link' data-aos={ `${ isMobile ? 'fade-down' : 'fade-down' }` } data-aos-duration="2000" to='contacto'>Contáctanos</Link>
          </span>
        </div>
      ))}
    </div>

    </>
  );
};
