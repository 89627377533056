import React from 'react';

export const SomosSection = () => {

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='container quienes-somos'  id='quienes-somos'>

            <ul>
                <li data-aos="fade-down" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720759307/construccion_ooik5x.png' alt='minibanner3' /></li>
                <li data-aos="fade-up" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720759797/control_de_acceso_bqycw8.png' alt='minibanner1' /></li>
                <li data-aos="fade-down" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720759880/document_ay6jfw.png' alt='minibanner2' /></li>
                <li data-aos="fade-up" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720759949/electricidad_e0irmn.png' alt='minibanner3' /></li>
                <li data-aos="fade-down" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720760022/huella_drszuq.png' alt='minibanner4' /></li>
                <li data-aos="fade-up" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720760200/redes_ivfusg.png' alt='minibanner2' /></li>
                <li data-aos="fade-down" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720760083/pro_z3edqm.png' alt='minibanner2' /></li>
                <li data-aos="fade-up" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720760482/remodelacion_yxolvu.png' alt='minibanner2' /></li>
                <li data-aos="fade-down" data-aos-duration="1000"><img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1240,h_1240,g_auto/v1720760527/seguridad_u6v9vm.png' alt='minibanner2' /></li>
            </ul>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Quiénes</span> Somos</h1>

            <div className='quienes-somos-text'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720757453/0_ai7ul9.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    INSERTEC Somos una empresa de ingenieria y servicios en el área 
                    de electricidad y construcciones generales en obras menores, 
                    orientada a la industria, comercio y al hogar. Contamos con el 
                    personal capacitado con conocimientos técnicos y de equipamientos 
                    para realizar cada proyecto. Estamos constantemente en proceso de 
                    formación y crecimiento.
                    <br />
                    <br />
                    Nuestra misión es proporcionar servicios de ingenieria de vanguardia 
                    para proyectos, construcción, remodelación y reparación. 
                </p>
            </div>
                <div className='texto-siguiente'>
                    <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000">
                    También 
                    crecer haciendo nuestro trabajo de forma responsable, eficiente y 
                    confiable mediante la aplicación de nuevas tecnologías en el área 
                    electrica y de la construcción, de tal modo de lograr satisfacer los 
                    requerimientos de nuestros clientes.
                    <br />
                    <br />
                    Aspiramos a ser una empresa conocida en todo el país por su liderazgo 
                    y competitividad empresarial, con tecnología de vanguardia un recurso 
                    humano de excelencia, con orgullo de pertenencia, motivado y calificado, 
                    que proporcione servicios y productos con la mayor calidad en su especialidad. 
                    </p>
                </div>

                <div className='img-agregada' data-aos="fade-down" data-aos-duration="1000">
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720757608/1_ipbytv.jpg' />
                </div>

        </div>

    </>
  );
};
