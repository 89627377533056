import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Tablero Electrico Bbraun',
        texto: 'Proyecto de Integración e Instalación de tableros eléctricos para empresa Bbraun medical en la comuna de San Bernardo. Tableros instalados en servicio técnico de dicha empresa que se encontraba en remodelación. Alimenta circuitos de alumbrado, fuerza y computación.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,ar_4:3/v1720858445/0_dejpc6.jpg', // Reduced resolution
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858445/1_dyioah.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858445/2_oke3vp.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858445/3_pbghmu.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858445/4_ggujd3.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858446/5_gybdyp.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858446/6_vwdurx.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858446/7_uh8zdp.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858446/8_sxyt0s.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858446/9_ifvljc.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858447/10_xyws4c.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858462/11_wrfcac.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858462/12_d9pus3.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858462/13_sqmjkg.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858462/14_qnk2d3.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720858462/15_yvjcbh.jpg',
        ],
        styles: 'fade-left'
    },
];

export const Braun = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
        setIndex(index);
        setIsOpen(true);
    };

    const isMobile = window.innerWidth < 1250;

    return (
        <>
            <div className='card' data-aos={`${isMobile ? 'zoom-in' : 'fade-right'}`} data-aos-duration="1000">
                <figure onClick={() => openGallery(0)}>
                    <img src={proyectosRealizados_1[0].imagePrincipal} alt=''/>
                </figure>
                <div className='contenido'>
                    <h3>{proyectosRealizados_1[0].nombre}</h3>
                    <p>{proyectosRealizados_1[0].texto}<br/><br/></p>
                    <Link onClick={() => setActiveLink('')} className='link' to='contacto'>Consulte por su Proyecto</Link>
                </div>
            </div>

            <PestañaGaleria
                key={proyectosRealizados_1[0].nombre}
                index={index}
                isOpen={isOpen}
                images={proyectosRealizados_1[0]}
                setIndex={setIndex}
                setIsOpen={setIsOpen}
            />
        </>
    );
};

