import React from 'react';
import { Link } from 'react-router-dom';

export const ServiciosSection = ({ setActiveLink }) => {

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className="main-nuestros-servicios">
            <div className="container nuestros-servicios">
                <h1 className="heading-1" data-aos="fade-down" data-aos-duration="1000">
                    <span>Nuestros</span> Servicios
                </h1>

                <div className="container-gallery" id='nuestros-servicios'>
                    <div className="card-servicio gallery-img-1" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-left' }` } data-aos-duration="1500">
                        <span className='card-title'>Electricidad</span>
                        {/*<div className="textServicio">
                            <p>
                                Ingeniería Desarrollo Y Ejecución De Proyectos 
                                Domiciliarios e Industriales, Instalaciones De 
                                Mediana Y Baja Tensión, Instalaciones y 
                                Mantenimiento de Tableros Eléctricos
                            </p>
                        </div> */}
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/electricidad' }><span className='ver-mas'>Ver más</span></Link>
                    </div>

                    <div className="card-servicio gallery-img-2" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-right' }` } data-aos-duration="1500">
                        <span className='card-title'>Construcción</span>
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/contruccion' }><span className='ver-mas'>Ver más</span></Link>
                    </div>

                    <div className="card-servicio gallery-img-3" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-left' }` } data-aos-duration="1500">
                        <span className='card-title'>Generación y Respaldo de Energía</span>
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/generacion-y-respaldo-de-energia' }><span className='ver-mas'>Ver más</span></Link>
                    </div>

                    <div className="card-servicio gallery-img-6" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-up' }` } data-aos-duration="1500">
                        <span className='card-title'>Automatización - Domótica</span>
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/automatizacion-domotica' }><span className='ver-mas'>Ver más</span></Link>
                    </div>

                    <div className="card-servicio gallery-img-5" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-down' }` } data-aos-duration="1500">
                        <span className='card-title'>Conectividad y Redes</span>
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/conectividad-y-redes' }><span className='ver-mas'>Ver más</span></Link>
                    </div>

                    <div className="card-servicio gallery-img-4" data-aos={ `${ isMobile ? 'zoom-in' : 'fade-right' }` } data-aos-duration="1500">
                        <span className='card-title'>Sistema de Seguridad</span>  
                        <Link onClick={ () => setActiveLink( '' ) } className='link' to={ 'nuestros-servicios/sistema-de-seguridad' }><span className='ver-mas'>Ver más</span></Link>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};
