import { fetchSinToken } from "../../helpers/fetch";

export const mandarMensaje = async( name, lastName, emailAddress, phone, subject, message  ) => {
  
  try {
    
    const resp = await fetchSinToken( 'email/contact', { name, lastName, emailAddress, phone, subject, message }, 'POST' );

    const body = await resp.json();

    return body;

    } 
    
    catch ( error ) {

      console.log( error )
      
    };

};