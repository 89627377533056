import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
  {
    nombre: 'Ampliacion 2do Piso',
    texto: 'Proyecto de ampliación de casa en la comuna de CORONEL. Se construye en un segundo piso 30 mt2 para habilitar 2 dormitorios y un baño. Se construyo tabiquería de madera, con revestimiento de fibrocemento. Se realizo muro de ladrillo fiscal y estuco para hacer de cortafuego. Además electricidad y gasfitería.',
    imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847527/0_byoz4u.jpg', // Reduced resolution
    galeria: [
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847607/1_l8xsrv.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847615/2_vct918.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847621/3_h3socs.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847628/4_zqmvvl.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847634/5_drdxxe.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847642/6_chyt0i.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847648/7_ndhi3w.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847654/8_edc7hd.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847662/9_zyr8fg.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847668/10_ivjhps.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847676/11_ufqeqx.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720847682/12_cxwfv4.jpg',
    ],
    styles: ''
  },
];

export const MejoraPorton = ({ setActiveLink }) => {

  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openGallery = (index) => {
    setIndex(index);
    setIsOpen(true);
  };

  const isMobile = window.innerWidth < 1250;

  return (
    <>
      <div className='card' data-aos={`${isMobile ? 'zoom-in' : 'fade-left'}`} data-aos-duration="1000">
        <figure onClick={() => openGallery(0)}>
          <img src={proyectosRealizados_1[0].imagePrincipal} alt='' />
        </figure>
        <div className='contenido'>
          <h3>{proyectosRealizados_1[0].nombre}</h3>
          <p>{proyectosRealizados_1[0].texto}</p>
          <Link onClick={() => setActiveLink('')} className='link' to='contacto'>Consulte por su Proyecto</Link>
        </div>
      </div>

      <PestañaGaleria
        key={proyectosRealizados_1[0].nombre}
        index={index}
        isOpen={isOpen}
        images={proyectosRealizados_1[0]}
        setIndex={setIndex}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
