import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServiciosUPS = () => {

  const isMobile = window.innerWidth < 1250;

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <>

        <Contruction />

        <div className='container quienes-somos servicios-enlace'>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Generación y Resp</span>aldo de Energía</h1>

            <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771895/1_vsklnx.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    Contamos con profesionales que diseñan la solución 
                    correcta para responder a cada requerimiento. Ayudando 
                    a la continuidad operativa con equipos de alta calidad. 
                    Ofrecemos Asesorías y Diseños eficientes, a la medida y 
                    de calidad para cada uno de nuestros clientes. Esto lo 
                    hacemos a través de la ingenieria, la innovación y la 
                    dedicación que le entregamos a cada uno de nuestros proyectos. 
                    Ofrecemos nuestros servicios a la industria, comercio, y
                    particulares. Contamos con servicios de contrato en mantenciones.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                <span>Servicios eléctricos:</span>
                    <br />
                    • Mantenciones eléctricas correctivas y preventivas
                    <br />
                    • Instalación y mantención de tableros eléctricos
                    <br />
                    • Instalaciones de mediana y baja tensión 
                    <br />
                    • Integración de proyectos eléctricos con climatización, UPS, generadores y redes
                    <br />
                    • Integración de tableros eléctricos
                    <br />
                    • Aumentos de capacidad y empalmes
                    <br />
                    • Instalaciones eléctricas industriales, comerciales, y domiciliarias
                    <br />
                    • Control y automatización 
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p data-aos="fade-right" data-aos-duration="1000">
                    <span>Servicios Generación de energía:</span>
                    <br />
                    • Instalación de sistemas Fotovoltaicos
                    <br />
                    • Mantenimiento de sistemas Fotovoltaicos
                    <br />
                    • Instalación de UPS monofásicas y trifásicas
                    <br />
                    • Mantenimiento preventivo y correctivo de UPS
                    <br />
                    • Cambio de baterías de UPS y Bancos de baterías
                    <br />
                    • Instalación de tablero BY-PASS
                    <br />
                    • Instalación de Grupos Electrógenos
                    <br />
                    • Mantenimiento preventivo y correctivo de Grupo Electrógenos
                    <br />
                    • Instalación de tableros de Transferencia
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771903/2_jacz1k.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img className='start' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_1152,h_750,g_auto/v1720827276/6_ecgykc.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                </div>
            </div>

            <div className='quienes-somos-text servicios-page'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771907/3_rxbea6.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771912/4_cutya8.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720771919/5_n9mma9.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
            </div>

        </div>

    </>
  );
};
