import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Barra Antipanico',
        texto: 'Proyecto de Instalación de barra antipánico para bodega de empresa DECATHLON en la comuna de Pudahuel. Se retira accesorio de puerta para la instalación de plancha de metal para tapar barra de emergencia y se agrega brazo hidráulico para el cierre automatico de la puerta.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846671/0_xbaktw.jpg',
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846864/1_qpnijx.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846870/2_wvj3kh.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846874/3_r2j9gp.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846880/4_erh5l6.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846884/5_uevb3p.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846890/6_eg9hfi.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846895/7_ud3ijc.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846901/8_g7aukl.jpg'
        ],
        styles: 'fade-left'
    },
];

export const ContruccionPropiedad = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
      setIndex(index);
      setIsOpen(true);
    };

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='card' data-aos={ `${ isMobile ? 'zoom-in' : '' }` } data-aos-duration="1000">
            <figure onClick={() => openGallery(0)} >
                <img className='barra-antipanico' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720846671/0_xbaktw.jpg' alt=''/>
            </figure>
            <div className='contenido'>
                <h3>{ proyectosRealizados_1[0].nombre }</h3>
                <p>{ proyectosRealizados_1[0].texto }</p>
                <Link onClick={ () => setActiveLink( '' )}  className='link' to='contacto'>Consulte por su Proyecto</Link>
            </div>
        </div>

        <PestañaGaleria
            key={ proyectosRealizados_1[0].nombre }
            index={ index } 
            isOpen={ isOpen } 
            images={ proyectosRealizados_1[0] }
            setIndex={ setIndex }
            setIsOpen={ setIsOpen } 
        />

    </>
  );
};
