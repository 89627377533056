import React, { useEffect, useState } from 'react';
import { ComponentMap } from './fragmentos/ComponentMap';
import { Contruction } from '../static/contruction/Contruction';
import { MensajeEnviado } from '../static/enviado/MensajeEnviado';
import { fetchSinToken } from '../helpers/fetch';
import { mandarMensaje } from './functions/mandarMensaje';
import { useNavigate } from 'react-router';

export const ContactoPage = () => {

    const isMobile = window.innerWidth < 1250;
  
    const [ active, setActive ] = useState('');
    const [ noActive, setNoActive ] = useState('');

    const isActive = ( event ) => {
      
      event.preventDefault();

      setNoActive( 'no-active' );
      setActive( 'active' );
      
      const { body } = mandarMensaje( name, lastName, emailAddress, phone, subject, message );

      console.log( body )
      
    };
    
    const [ inputValue, setInputValue ] = useState({

      name: '',
      lastName: '',
      emailAddress: '',
      phone: '',
      subject: '',
      message: ''

    });
    
    const handleInputChange = ( { target } ) => {
    
      setInputValue({
    
        ...inputValue,
        [ target.name ] : target.value
    
      });
    
    };

    const { name, lastName, emailAddress, phone, subject, message } = inputValue;

    useEffect(() => {
      // Función que hace scroll hasta el tope
      window.scrollTo(0, 0);
    }, [  ] );


    return (
      <>

      <Contruction />

      <div className={ `formulario-contacto ${ noActive }` }>
        <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000">
            <span>Contactate con</span> Nosotros
        </h1>
        <div className='container-soporte' data-aos="fade-up" data-aos-duration="1000">
          <div className='soporte'>
            <p>
            Si aún no tienes claro tu proyecto, podemos asesorarte sin costo y ver las mejores alternativas según tu presupuesto. Llena el formulario y nos comunicaremos oportunamente
            </p>
          </div>
        </div>
        <div className='content-formulario'>


          <div className='formulario' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
            <h3>Haz tus consultas y cotiza tu presupuesto</h3>
            <form onSubmit={ isActive }>
              
              <div className='input-name'>
                <input 
                  className='nombre' 
                  placeholder='Nombre'
                  required
                  type='text' 
                  name='name'
                  value={ name }
                  onChange={ handleInputChange }
                />
                <input 
                  className='apellido' 
                  placeholder='Apellido' 
                  required
                  type='text' 
                  name='lastName'
                  value={ lastName }
                  onChange={ handleInputChange }
                />
              </div>

              <div className='input-contactos'>
                <input 
                  placeholder='Email'                  
                  required
                  type='email' 
                  name='emailAddress'
                  value={ emailAddress }
                  onChange={ handleInputChange }
                />
                <input 
                  placeholder='Celular'
                  required
                  type='text' 
                  name='phone'
                  value={ phone }
                  onChange={ handleInputChange }
                />
              </div>

              <div className='input-asunto'>
                <input 
                  placeholder='Asunto' 
                  type='text' 
                  name='subject'
                  value={ subject }
                  onChange={ handleInputChange }
                />
              </div>

              <div className='input-mensaje'>
                <textarea 
                  placeholder='Mensaje'
                  required
                  type='text' 
                  name='message'
                  value={ message }
                  onChange={ handleInputChange }
                  >
                </textarea>
              </div>

              <button type='submit'>Enviar</button>

            </form>
          </div>

          <div className='formulario-redes-sociales' data-aos={ `${ isMobile ? 'fade-up' : 'fade-right' }` } data-aos-duration="1000">

            <ComponentMap />

            <h3>Contactanos</h3>
            <div className='menu-redes'>
              <span><i className="fa-solid fa-location-dot"></i>Dirección: AV. Apoquindo 6410</span>
              <p className='direccion'>OF 605 PS 6, Las Condes</p>
              <p><i className="fa-regular fa-envelope"></i>EmaiL: contacto@insertec-cl.cl</p>
              <p><i className="fa-solid fa-phone"></i>Teléfono: +569 26199538</p>
              <ul>
                <li><a target="_blank" href='https://www.facebook.com/insertecchile'><i className="fa-brands fa-facebook"></i></a></li>
                <li><a target="_blank" href='https://www.instagram.com/insertec.cl/'><i className="fa-brands fa-instagram"></i></a></li>
                <li><a target="_blank" href='https://www.linkedin.com/company/inserte-cl/?viewAsMember=true'><i className="fa-brands fa-linkedin"></i></a></li>
                <li><a target="_blank" href='https://api.whatsapp.com/send/?phone=56926199538&text&type=phone_number&app_absent=0'><i className="fa-brands fa-whatsapp"></i></a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <MensajeEnviado active={ active } />

    </>
  );
};
