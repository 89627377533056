import React from 'react';
import img from '../../img/pagina-en-construcción2.png';
import '../../styles/contruction.css';

export const Contruction = () => {
  return (
    <div className='sitio-contruccuion'>
        <img src={ img } alt='en contruccion' />
    </div>
  );
};
