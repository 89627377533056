import React, { useRef } from 'react';
import { CounterAutomatic } from '../functions/CounterAutomatic';

const contadores = [
    {
      'name': 'Proyectos Realizados',
      'contador_inicial': 1,
      'contador_actual': 50,
      'seconds': 40,
    },
    {
      'name': 'Años de Experiencia',
      'contador_inicial': 1,
      'contador_actual': 10,
      'seconds': 210,
    },
    {
      'name': 'Clientes',
      'contador_inicial': 1,
      'contador_actual': 100,
      'seconds': 20,
    },
  ];

export const ContadorAutomaticoSection = () => {

      const contadorRef_1 = useRef( null );
      const contadorRef_2 = useRef( null );
      const contadorRef_3 = useRef( null );

      const { counter: counter_Proyectos }   = CounterAutomatic( contadores[ 0 ], contadorRef_1 );
      const { counter: counter_Experiencia } = CounterAutomatic( contadores[ 1 ], contadorRef_2 );
      const { counter: counter_Clientes }    = CounterAutomatic( contadores[ 2 ], contadorRef_3 );

  return (
    <>

        <div className="contadores-principal">

            <div ref={ contadorRef_1 } className="contador ocultar">
                <div className="contador_cantidad">+ { counter_Proyectos }</div>
                <h2>Proyectos Realizados</h2>
            </div>

            <div ref={ contadorRef_2 } className="contador ocultar">
                <div className="contador_cantidad">+ { counter_Experiencia }</div>
                <h2>Años de Experiencia</h2>
            </div>

            <div ref={ contadorRef_3 } className="contador ocultar">
                <div className="contador_cantidad">+ { counter_Clientes }</div>
                <h2>Clientes</h2>
            </div>

        </div>

    </>
  );
};
