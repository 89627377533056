import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const center = {
  lat: -33.40758904788469,
  lng: -70.56437491972532
};

export const ComponentMap = () => {

  const [ styles, setStyles ] = useState({
      height: '280px',
      width: '400px'
  });

  const isMobile = window.innerWidth < 510;
  const isMobile2 = window.innerWidth < 450;
  const isMobile3 = window.innerWidth < 395;

  useEffect( () => {
    
    if ( isMobile ) {
      
      return setStyles({
        height: '280px',
        width: '370px'
      });
      
    }
    
  }, [] );

  useEffect( () => {
    
    if ( isMobile2 ) {
      
      return setStyles({
        height: '280px',
        width: '320px'
      });
      
    }
    
  }, [] );

  useEffect( () => {
    
    if ( isMobile3 ) {
      
      return setStyles({
        height: '280px',
        width: '280px'
      });
      
    }
    
  }, [] );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCIzw6TartP0E8C6wox4thtpvF2WHKrM9U"
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, [ map ]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (!isLoaded) {
    return <h1>Loading</h1>;
  }

  return (
    <GoogleMap
      mapContainerStyle={styles}
      zoom={15}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
    </GoogleMap>
  );
};
