import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Barrera de Acceso Vehicular',
        texto: 'Proyecto de Instalación de Barrera NICE para control de acceso vehicular para la empresa Semillas Pioneer en la comuna de Paine.  Canalizados previos, instalación lectora de tarjetas para dar apertura de barrera, sensor fotocelda de corte para que no baje mientras se encuentre un vehículo en la zona.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845690/0_z5n6co.jpg' ,
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845691/1_svstad.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845690/2_ekckyt.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845691/3_ajj8fq.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845690/4_qk5czz.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845690/5_vosy9i.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845691/6_xqopyz.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845691/7_j51v57.jpg',

        ],
        styles: 'fade-right'
    },
];

export const CercaElectrica = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
      setIndex(index);
      setIsOpen(true);
    };

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='card' data-aos={ `${ isMobile ? 'zoom-in' : 'fade-right' }` } data-aos-duration="1000">
            <figure onClick={() => openGallery(0)} >
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720845690/0_z5n6co.jpg' alt=''/>
            </figure>
            <div className='contenido'>
                <h3>{ proyectosRealizados_1[0].nombre }</h3>
                <p>{ proyectosRealizados_1[0].texto }</p>
                <Link onClick={ () => setActiveLink( '' )}  to='contacto'>Consulte por su Proyecto</Link>
            </div>
        </div>

        <PestañaGaleria
            key={ proyectosRealizados_1[0].nombre }
            index={ index } 
            isOpen={ isOpen } 
            images={ proyectosRealizados_1[0] }
            setIndex={ setIndex }
            setIsOpen={ setIsOpen } 
        />

    </>
  );
};
