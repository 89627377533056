import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { Autoplay, Pagination, Navigation, FreeMode } from 'swiper/modules';

export const ConfiaronSection = () => {

  const [ slider, setSlider ] = useState( 5 );

  const [ slider2, setSlider2 ] = useState( 2 );

  const isMobile = window.innerWidth < 1250;

  const isMobile2 = window.innerWidth < 530;

  const isMobile3 = window.innerWidth < 760;

  const isMobile4 = window.innerWidth < 500;


  useEffect( () => {

    if ( isMobile2 ) {

      setSlider( 1 );

      return setSlider2( 120 );

    }

    if ( isMobile3 ) {

      setSlider( 2 );

      return setSlider2( 5 );

    }

    if ( isMobile4 ) {

      setSlider( 1 );

      return setSlider2( 100 );

    }

    if ( isMobile ) {

      setSlider( 3 );

      return setSlider2( 100 );

    }

  }, [ isMobile, isMobile2, isMobile3, isMobile4 ] );


  return (
    <>

      <div className='container-confiaron' id='experiencia'>
        <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Ellos Confiaron</span> En Nosotros</h1>
          <ul>
            <Swiper
            className='swiper'
              modules={[ Autoplay, Pagination, Navigation, FreeMode ]}
              spaceBetween={ slider2 }
              slidesPerView={ slider }
              pagination={ true }
              loop={ true }
              freeMode={true}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
            >
              

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835926/images_ltrykf.png' alt='' className='confiaron3' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835922/eme-a_pgfbki.png' alt='' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835922/hidroservitec_whzgqz.png' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://grandesmarcas.cl/wp-content/uploads/2016/06/logo-Soprole.jpg' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://mir-s3-cdn-cf.behance.net/projects/404/60e55870688401.Y3JvcCw4MDgsNjMyLDAsMA.jpg' alt='' className='confiaron3' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835904/braun_b5zlu9.png' alt='' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://seeklogo.com/images/L/lions-club-international-logo-8A8F864998-seeklogo.com.png' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>
              

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720842255/decathlon-logo-vector_mfxncp.svg' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835927/pizza_qe5pt2.png' alt='' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720835921/dupont_qlqosk.png' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='https://media.licdn.com/dms/image/D4E03AQF5zkz2sSkWfg/profile-displayphoto-shrink_200_200/0/1678126105683?e=2147483647&v=beta&t=HLZMbhJgI1pvcLqwnh1WaiiJLSt0wqhZHXXVNatOzVc' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2oEKB5Jq5lHCGumsY8pXdOTCBknruxvZjoQ&s' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-down" data-aos-duration="1000">
                  <img src='http://benales.cl/wp-content/uploads/2021/03/logo_web_benales.png' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

              <SwiperSlide className='swiper-slide'>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img src='https://media.licdn.com/dms/image/C560BAQHPqZ8ous8Q5A/company-logo_200_200/0/1641228732717/clinicadelcarmenstgo_logo?e=2147483647&v=beta&t=4lLeBwHtgog3ZOkkP_vakG73Z7rcrmPyHNUeSkju1H8' alt='' className='confiaron2' />
                </li>
              </SwiperSlide>

            </Swiper>
          </ul>
      </div>

    </>
  );
};
