import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServiciosAutomatizaciónDomótica = () => {

  const isMobile = window.innerWidth < 1250;

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <>

        <Contruction />

        <div className='container quienes-somos servicios-enlace'>

            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Automatización</span> Domótica</h1>

            <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765128/1_ciifv4.jpg' alt='quienes-somos'  data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p  data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    Ingenieria y Diseño para soluciones de Automatización 
                    de sistemas eléctricos y de seguridad. Ofrecemos una 
                    amplia gama de servicios especializados en la domótica 
                    para hacer tu vida mas inteligente y segura. Nuestro 
                    equipo es altamente especializado para brindar 
                    capacitaciones y orientar en domótica, garantizando que 
                    estés totalmente equipado para aprovechar al máximo las 
                    tecnologías inteligentes.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p  data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                    <span>Servicios de automatización y domótica:</span>
                    <br />
                    • Instalación de interruptores inteligentes
                    <br />
                    • Instalación de video porteros inteligentes
                    <br />
                    • Sistemas de CCTV Wifi
                    <br />
                    • Automatización de chapas eléctricas, Portones de acceso de personal y vehicular
                    <br />
                    • Sensores inteligentes
                    <br />
                    • Sistema KNX
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p  data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                    <span>Servicios de automatización y domótica:</span>
                    <br />
                    • Instalación de interruptores inteligentes
                    <br />
                    • Instalación de video porteros inteligentes
                    <br />
                    • Sistemas de CCTV Wifi
                    <br />
                    • Automatización de chapas eléctricas, Portones de acceso<br /><span className='texto-adicional'>de personal y vehicular</span>
                    <br />
                    • Sensores inteligentes
                    <br />
                    • Sistema KNX
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765132/2_bl9pef.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765141/3_xbbbd8.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                </div>
            </div>

            

            <div className='quienes-somos-text servicios-page'>
                <img className='imagen1' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765149/4_tgfrqg.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765164/5_cvjuby.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` } data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720765168/6_u8s4rs.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
            </div>

        </div>

    </>
  );
};
