import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Tablero By-Pass',
        texto: 'Proyecto de integración e instalación de tablero BY-PASS para la empresa Distintec en la comuna de lampa. Armado de tablero con selectores de 3 posiciones. Con indicador led para indicar si esta en UPS o RED directa.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856749/0_pm9dem.jpg',
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856764/1_vkuyyp.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/2_uyx2aa.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/3_yhvzkz.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/4_prgjy0.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/5_ha5byt.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/6_f5x33f.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856765/7_pcnx83.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856774/9_ntajn3.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856774/10_cjb5h5.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856774/11_dfh8x5.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856775/12_mvkm6o.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720856775/13_y6qcnt.jpg'
        ],
        styles: 'fade-left'
    },
];

export const Tablero = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
      setIndex(index);
      setIsOpen(true);
    };

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='card' data-aos={ `${ isMobile ? 'zoom-in' : '' }` } data-aos-duration="1000">
            <figure onClick={() => openGallery(0)} >
                <img className='tablero' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,ar_4:3/v1720856749/0_pm9dem.jpg' alt=''/>
            </figure>
            <div className='contenido'>
                <h3>{ proyectosRealizados_1[0].nombre }</h3>
                <p>{ proyectosRealizados_1[0].texto }<br /><br /><br /><br/></p>
                <Link onClick={ () => setActiveLink( '' )}  className='link' to='contacto'>Consulte por su Proyecto</Link>
            </div>
        </div>

        <PestañaGaleria
            key={ proyectosRealizados_1[0].nombre }
            index={ index } 
            isOpen={ isOpen } 
            images={ proyectosRealizados_1[0] }
            setIndex={ setIndex }
            setIsOpen={ setIsOpen } 
        />

    </>
  );
};