import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

export const MensajeEnviado = ( { active } ) => {

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [ active ] );

  return (
    <div className={ `mensaje-enviado ${ active } animate__animated animate__zoomIn` } id='mensaje-enviado'>
        <div className='content-mensaje'>
            <h3>Mensaje Enviado!</h3>
            <p>
                Pronto nos pondremos en contacto con usted, atento a su correo electronico o su celular.
                <br/>
                <span>Le contestaremos dentro del siguiente horario: Lunes a Viernes 9 a 18 Hrs</span>
            </p>
            <ul>
                <li><a href='https://www.facebook.com/insertecchile'><i className="fa-brands fa-facebook"></i></a></li>
                <li><a href='https://www.instagram.com/insertec.cl/'><i className="fa-brands fa-instagram"></i></a></li>
                <li><a href='https://www.linkedin.com/company/inserte-cl/?viewAsMember=true'><i className="fa-brands fa-linkedin"></i></a></li>
                <li><a href='https://api.whatsapp.com/send/?phone=56926199538&text&type=phone_number&app_absent=0'><i className="fa-brands fa-whatsapp"></i></a></li>
              </ul>
            <span><a className='link' href='/'>Regresar</a></span>
        </div>
    </div>
  );
};
