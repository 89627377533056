import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
  {
    nombre: 'Remodelacion Parcial Baño',
    texto: 'Proyecto de remodelación parcial de baño en la comuna de puente alto, retiro de tina y shower, se realiza instalación de desagüé o pileta, reparación de piso y muros para la instalación de cerámicos, instalación de bloques de vidrio, cambio de monomando y regadera.',
    imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_4000,h_3000/v1720854166/0_krjthx.jpg', // Reduced resolution
    galeria: [
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854167/1_dj1zvc.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854167/2_yektxu.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854172/3_kwuwmn.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854173/4_unfskn.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854250/5_jnig9p.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854254/6_sf9cfy.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854255/7_dfgkct.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854260/9_veoyhv.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854263/10_ceoo5q.jpg',
      'https://res.cloudinary.com/dy1o5vcd8/image/upload/c_scale,w_600/v1720854265/11_tzcn1f.jpg',
    ],
    styles: 'fade-left'
  },
];

export const Baño = ({ setActiveLink }) => {

  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openGallery = (index) => {
    setIndex(index);
    setIsOpen(true);
  };

  const isMobile = window.innerWidth < 1250;

  return (
    <>
      <div className='card' data-aos={`${isMobile ? 'zoom-in' : 'fade-left'}`} data-aos-duration="1000">
        <figure onClick={() => openGallery(0)}>
          <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/c_crop,w_4000,h_3000/v1720854166/0_krjthx.jpg' alt='' /> {/* Reduced resolution */}
        </figure>
        <div className='contenido'>
          <h3>{proyectosRealizados_1[0].nombre}</h3>
          <p>{proyectosRealizados_1[0].texto}<br /><br /></p>
          <Link onClick={() => setActiveLink('')} className='link' to='contacto'>Consulte por su Proyecto</Link>
        </div>
      </div>

      <PestañaGaleria
        key={proyectosRealizados_1[0].nombre}
        index={index}
        isOpen={isOpen}
        images={proyectosRealizados_1[0]}
        setIndex={setIndex}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
