import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Cobertizo y Meson para Quincho',
        texto: 'Proyecto de cobertizo y mesón para quincho en la comuna del canelillo, se realiza radier de 16 m2, instalación de cobertizo de madera 2x8 y 2x6 pulgadas con empalizado de listones 1x2, cubierta de policarbonato, se realiza mesón en el centro de concreto.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720852714/0_vg9lqr.jpg',
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853098/1_tbvjx9.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853104/2_uvg3vu.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853111/3_bgbtuq.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853120/4_apcvpt.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853128/5_whcvvm.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853135/6_ymhqy0.jpg',
        ],
        styles: 'fade-left'
    },
];

export const Cobertizo = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
      setIndex(index);
      setIsOpen(true);
    };

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='card' data-aos={ `${ isMobile ? 'zoom-in' : '' }` } data-aos-duration="1000">
            <figure onClick={() => openGallery(0)} >
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720852714/0_vg9lqr.jpg' alt=''/>
            </figure>
            <div className='contenido'>
                <h3>{ proyectosRealizados_1[0].nombre }</h3>
                <p>{ proyectosRealizados_1[0].texto }<br/><br/></p>
                <Link onClick={ () => setActiveLink( '' )}  className='link' to='contacto'>Consulte por su Proyecto</Link>
            </div>
        </div>

        <PestañaGaleria
            key={ proyectosRealizados_1[0].nombre }
            index={ index } 
            isOpen={ isOpen } 
            images={ proyectosRealizados_1[0] }
            setIndex={ setIndex }
            setIsOpen={ setIsOpen } 
        />

    </>
  );
};
