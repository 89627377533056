import React from 'react';
import { ContruccionPropiedad } from '../proyectos/ContruccionPropiedad';
import { MejoraPorton } from '../proyectos/MejoraPorton';
import { CercaElectrica } from '../proyectos/CercaElectrica';
import { Electrogeno } from '../proyectos/Electrodeno';
import { Cobertizo } from '../proyectos/Cobertizo';
import { Motor } from '../proyectos/Motor';
import { Baño } from '../proyectos/Baño';
import { Tablero } from '../proyectos/Tablero';
import { Braun } from '../proyectos/Braun';


export const ProyectosRealizadosSection = ({ setActiveLink }) => {

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='container proyectos-realizados' id='proyectos-realizados'>
            <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000">
                <span>Proyectos</span> Realizados
            </h1>

            <div className='content-proyectos-realizados'>

                <MejoraPorton setActiveLink={setActiveLink} />
                <ContruccionPropiedad setActiveLink={setActiveLink} />
                <CercaElectrica setActiveLink={setActiveLink} />

            </div>
            <div className='content-proyectos-realizados'>

                <Electrogeno setActiveLink={setActiveLink} />
                <Cobertizo setActiveLink={setActiveLink} />
                <Motor setActiveLink={setActiveLink} />

            </div>
            <div className='content-proyectos-realizados linear'>

                <Baño setActiveLink={setActiveLink} />
                <Tablero setActiveLink={setActiveLink} />
                <Braun setActiveLink={setActiveLink} />

            </div>

        </div> 

    </>
  );
};
