import React from 'react';
import { AppRouter } from './routes/AppRouter';

export const App = () => {

  return (
    <>

      <AppRouter />

    </>
  );
};
