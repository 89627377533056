import React, { useEffect } from 'react';
import { 
    BannerSection,
    ContadorAutomaticoSection,
    SomosSection,
    ServiciosSection,
    ProyectosRealizadosSection,
    ConfiaronSection
} from './fragmentos/export';
import { Contruction } from '../static/contruction/Contruction';

export const InicioPage = ({ setActiveLink }) => {

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <main className='main'>

        <BannerSection setActiveLink={ setActiveLink } />

        <SomosSection />

        <ContadorAutomaticoSection />

        <ServiciosSection setActiveLink={setActiveLink} />

        <ProyectosRealizadosSection setActiveLink={setActiveLink} />

        <ConfiaronSection />

        <Contruction />

    </main>
  );
};