import React, { useEffect } from 'react';
import { Contruction } from '../../static/contruction/Contruction';

export const ServiciosCDAcceso = () => {

  const isMobile = window.innerWidth < 1250;

  useEffect(() => {
    // Función que hace scroll hasta el tope
    window.scrollTo(0, 0);
  }, [  ] );

  return (
    <>

    <Contruction />

    <div className='container quienes-somos servicios-enlace'>

        <h1 className='heading-1' data-aos="fade-down" data-aos-duration="1000"><span>Conectividad</span> y Redes</h1>

        <div className='quienes-somos-text servico'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720770283/1_hnuhye.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000" />

                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000">
                    En INSERTEC entendemos y comprendemos que cada empresa 
                    es un universo distinto y que necesita soluciones 
                    personalizadas, de acuerdo a sus propias necesidades, 
                    es por eso que el nuestro compromiso, es entregar la 
                    solución más óptima para su negocio. Para ello ofrecemos 
                    soluciones en diseño e instalaciones, mantenimiento y 
                    soporte, en redes de datos, telefonía, fibra óptica, 
                    citofonía, entre otros.
                </p>
            </div>

            <div className='servicio-responsive'>
                <p data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` } data-aos-duration="1000">
                    <span>Servicios de conectividad y redes:</span>
                    <br />
                    • Instalación y certificación de cableado estructurado
                    <br />
                    • Instalación y certificación de fibra óptica
                    <br />
                    • Servicio de mantención correctiva y preventiva
                    <br />
                    • Diseño, montaje y puesta en marcha de data center
                    <br />
                    • Estudio, análisis y montaje de amplificadores de señal celular
                    <br />
                    • Diseño e instalaciones de redes inalámbricas
                </p>
            </div>

            <div className='quienes-somos-text servicios'>
                <p data-aos="fade-right" data-aos-duration="1000">
                    <span>Servicios de conectividad y redes:</span>
                    <br />
                    • Instalación y certificación de cableado estructurado
                    <br />
                    • Instalación y certificación de fibra óptica
                    <br />
                    • Servicio de mantención correctiva y preventiva
                    <br />
                    • Diseño, montaje y puesta en marcha de data center
                    <br />
                    • Estudio, análisis y montaje de amplificadores de señal celular
                    <br />
                    • Diseño e instalaciones de redes inalámbricas
                </p>
                <div className='imagenes2'>
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720770288/2_fei3dh.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` } data-aos-duration="1000" />
                    <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720770293/3_hgxhxo.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` }  data-aos-duration="1000" />
                </div>
            </div>

            <div className='quienes-somos-text servicios-page'>
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720770297/4_qfaxbj.avif' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-right' }` }  data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720770304/5_jndwtu.jpg' alt='quienes-somos' data-aos={ `${ isMobile ? 'fade-down' : 'fade-up' }` }  data-aos-duration="1000" />
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720828855/6_v8ftxm.webp' alt='quienes-somos'data-aos={ `${ isMobile ? 'fade-down' : 'fade-left' }` }  data-aos-duration="1000" />
            </div>

    </div>

    </>
  );
};