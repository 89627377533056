import React, { useState } from 'react';
import { PestañaGaleria } from '../fragmentos/PestañaGaleria';
import { Link } from 'react-router-dom';

const proyectosRealizados_1 = [
    {
        nombre: 'Motor Nice para Porton',
        texto: 'Proyecto de Instalación de motor de la marca NICE modelo Robus. Para portón en edificio corporativo de Soprole ubicado en la comuna de Vitacura. Instalación de cremalleras metálicas adecuadas para el motor instalado, conectado a pulsador de apertura en recepción del edificio.',
        imagePrincipal: 'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853544/0_asl9cw.jpg',
        galeria: [
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853545/1_kizn1z.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853547/2_rzzkqh.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853550/3_fp2qb3.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853551/4_lsffq8.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853554/5_ac8ah2.jpg',
            'https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853555/6_wcry8y.jpg',
        ],
        styles: 'fade-left'
    },
];

export const Motor = ({ setActiveLink }) => {

    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openGallery = (index) => {
      setIndex(index);
      setIsOpen(true);
    };

  const isMobile = window.innerWidth < 1250;

  return (
    <>

        <div className='card' data-aos={ `${ isMobile ? 'zoom-in' : 'fade-right' }` } data-aos-duration="1000">
            <figure onClick={() => openGallery(0)} >
                <img src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720853544/0_asl9cw.jpg' alt=''/>
            </figure>
            <div className='contenido'>
                <h3>{ proyectosRealizados_1[0].nombre }</h3>
                <p>{ proyectosRealizados_1[0].texto }</p>
                <Link onClick={ () => setActiveLink( '' )}  className='link' to='contacto'>Consulte por su Proyecto</Link>
            </div>
        </div>

        <PestañaGaleria
            key={ proyectosRealizados_1[0].nombre }
            index={ index } 
            isOpen={ isOpen } 
            images={ proyectosRealizados_1[0] }
            setIndex={ setIndex }
            setIsOpen={ setIsOpen } 
        />

    </>
  );
};
