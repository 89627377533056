import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

export const PestañaGaleria = ( {index, isOpen, images, setIndex, setIsOpen} ) => {
  
    const [styles, api] = useSpring(() => ({ x: 0 }));

    const [ counter, setCounter ] = useState( 1 );
  
    const bind = useDrag(({ movement: [mx], direction: [xDir], distance, cancel }) => {
      if (distance > 100) {
        if (xDir > 0) {
          prevImage();
        } else {
          nextImage();
        }
        cancel();
      }
      api.start({ x: mx });
    });
  
    const nextImage = () => {

      setIndex( (state) => (state + 1) % images.galeria.length );

      if ( counter === images.galeria.length ) {

        setCounter( 1 )

      } else {

        setCounter( ( state ) => state + 1 )

      }

    };

    const prevImage = () => {

      setIndex((state) => (state - 1 + images.galeria.length) % images.galeria.length);

      if ( counter === 1 ) {

        setCounter( images.galeria.length )

      } else {

        setCounter( ( state ) => state - 1 )

      }

    }

  
    const closeGallery = () => {
      setIsOpen(false);
      setCounter( 1 );
    };

    return (
<div>
  {isOpen && (
    <div className="lightbox" data-aos="zoom-in">
      <button className="close" onClick={closeGallery}><i className="fa-solid fa-xmark"></i></button>
      <button className="prev" onClick={prevImage}><i className="fa-solid fa-chevron-left"></i></button>
      <button className="next" onClick={nextImage}><i className="fa-solid fa-chevron-right"></i></button>
      <animated.div {...bind()} style={{ transform: styles.x.to((x) => `translate3d(${x}px,0,0)`) }}>
        <img src={images.galeria[index]} alt={`image-${index}`} />
      </animated.div>
      <div className="thumbnail-grid">
        {images.galeria.map((src, i) => (
          <img
            key={i}
            src={src}
            alt={`thumbnail-${i}`}
            onClick={() => setIndex(i)}
            className={i === index ? 'thumbnail selected' : 'thumbnail'}
          />
          
        ))}
      </div>
          <div className='menu-galeria'>
            <img onClick={prevImage} className='thumbnail atras' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721242933/proximo_1_yfhzse.png' />
              <p>
                { counter } / { images.galeria.length }
              </p>
            <img onClick={nextImage} className='thumbnail siguiente' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721242774/proximo_k8dynd.png' />
          </div>
          <img onClick={closeGallery} className='thumbnail deleted' src='https://res.cloudinary.com/dy1o5vcd8/image/upload/v1721242655/boton-eliminar_1_a9oght.png'/>
    </div>
  )}
</div>
    );
  };
