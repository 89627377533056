import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import { Socials, Navbar, Footer } from '../static/export';
import { routes } from './routes';
import { 
  ServiciosElectricidad, 
  ServiciosCDAcceso, 
  ServiciosUPS, 
  ServiciosAutomatizaciónDomótica, 
  ServiciosContruccion,
  ServicioSeguridad  
} from '../pages/servicios/export';
import Aos from 'aos';
import { useState } from 'react';


export const AppRouter = () => {

  const [activeLink, setActiveLink] = useState('/');

  Aos.init();

  return (
    <BrowserRouter>

      <Socials />
      
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />

      <Routes>

        {
          routes.map(  ({ id, path, Component }) => (

            <Route key={ id } path={ path } element={ <Component setActiveLink={setActiveLink} /> } />
          ))
        }

        <Route path='nuestros-servicios/contruccion' element={ <ServiciosContruccion /> } />
        <Route path='nuestros-servicios/electricidad' element={ <ServiciosElectricidad /> } />
        <Route path='nuestros-servicios/conectividad-y-redes' element={ <ServiciosCDAcceso /> } />
        <Route path='nuestros-servicios/sistema-de-seguridad' element={ <ServicioSeguridad /> } />
        <Route path='nuestros-servicios/generacion-y-respaldo-de-energia' element={ <ServiciosUPS /> } />
        <Route path='nuestros-servicios/automatizacion-domotica' element={ <ServiciosAutomatizaciónDomótica /> } />

        <Route path='/*' element={ <Navigate to='/' /> } />

      </Routes>

      <Footer setActiveLink={setActiveLink} />

    </BrowserRouter>
  );
};
